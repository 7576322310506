import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col, Container} from 'react-bootstrap'
import styled from 'styled-components'
import TeamItem from 'sections/team/parts/TeamItem.js'
import AnimatedHeading from 'components/animated-heading'

class TeamTwo extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        show: false,
      }

      this.toggle = this.toggle.bind(this);
    }

    toggle(){
      this.setState({show:!this.state.show});
      console.log(this.state.show);
    }

    shouldComponentUpdate() {
      return false
    }

    render() {

        const Section = styled.section`
          background-image: url("/img/blue-tech-background-with-dotted-particles-and-lines-abstract-scientific-digital-illustration-with_t20_dz6jAR.jpg");
          background-size: cover;
          padding: 100px 0;
          position: relative;

        `

        const Overlay = styled.div`
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          background-color: rgba(0,0,0,.8);
        `
        const TeamContainer = styled.div`
            padding: 50px 0 0 0;
            @media (max-width:767px) {
              padding: 0 50px;
            }
        `

        const overlayClassName = this.state.show
        ? "modal fade show"
        : "modal fade";


        return (
          <React.Fragment>
            <Section id="products">
                  <Overlay />
                  <Col md={12}>
                    <Container>
                      <AnimatedHeading text="Sample Products" />
                      <p style={{color:"white", textAlign:"center"}}>
                        Click through these sample products to give you an idea of where to start. Even think of rebranding these samples as your own!<br />
                        No matter what type of business you are we will create you a platform with tools to optimize your daily tasks and bring awareness to your brand!
                      </p>
                    </Container>
                  <TeamContainer>
                    <Container>
                      <Row>
                        {this.team()}
                      </Row>
                    </Container>
                  </TeamContainer>
                </Col>
            </Section>
          </React.Fragment>
        )
    }

  team() {
      const { items } = this.props
      return items.map((value, index) => {
        return (
          <Col md={3} key={index}>
            <TeamItem
                index={index}
                image={value.content.frontmatter.image.childImageSharp.fluid.src}
                name={value.content.frontmatter.name}
                profession={value.content.frontmatter.profession}
                link={value.content.frontmatter.link}
                type="col"
            />
          </Col>
        )
      })
    }
}

export default props => (
  <StaticQuery
      query={graphql`
          query {
            background: file(relativePath: {eq: "bg3.jpg"}) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  src
                }
              }
            }
            items: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(team)/"}}, sort: {fields: [frontmatter___id], order: ASC}, limit: 8) {
              edges {
                content: node {
                  frontmatter {
                    id
                    name
                    profession
                    link
                    image {
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          `}
      render={({ items, background }) => <TeamTwo items={items.edges} background={background} {...props} />}
  />
)
